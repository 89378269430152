import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Menu from '../components/menu';

const AboutPage = () => (
  <Layout>
    <SEO title="O stronie" />
    <header>
      <Menu></Menu>
    </header>
    <section class="page-content">
      <h1 class="section-header">O stronie</h1>
      <p>Cześć!</p>
      <p>
        Ta strona to notatnik do jednego z moich hobby - gotowania. Jest to
        jednocześnie moja prywatna książka kucharska, zbiór przemyśleń, porad i
        być może w przyszłości recenzji. Jeśli właśnie to czytasz, to zapewne
        dostałeś link do jakiegoś wpisu od moich przyjaciół lub ich przyjaciół,
        co mnie niezmiernie cieszy.
      </p>
      <p>
        Żeby wykonać większość przepisów potrzebujesz znać podstawy gotowania.
        Często składniki nie będą idealnie wymierzone, a same pomysły radzę
        traktować raczej jako formę inspiracji. Pochodzą one z różnych miejsc,
        jest sporo autorskich, trochę improwizacji na znane tematy, ale i
        czasami to modyfikacje innych, znalezionych w sieci (tam, gdzie tak
        jest, zobaczycie źródło).
      </p>
      <p>
        Mam nadzieję, że coś z tego się przyda, a jeśli masz ochotę zamienić ze
        mną słowo, to najlepiej wysłać do mnie wiadomość na Instagramie, do
        którego link znajduje się w menu.
      </p>
      <p style={{ textAlign: 'right' }}>Kamil</p>
    </section>
  </Layout>
);

export default AboutPage;
